import * as React from "react"
import styled from "@emotion/styled"
import { StaticQuery, graphql } from "gatsby"

import { NewsletterInput } from "../new-ui/newsletter-signup"
import useWindowSize from "../../hooks/useWindowSize"
import { isMobileWindow } from "../../utils/render-util"

import { InterfaceContext } from "../../context/InterfaceContext"
import { useStore } from "../../context/NewStoreContext"
import {
  useIsZendeskLiveChatActive,
  useZendesk,
} from "../../hooks/v2/useZendesk"

import renderNavigationItem from "../../utils/navigation-item"

const Bottom = styled.div`
  font-size: 15px;
  display: flex;
  border-top: 1px solid #e5e3e4;
  padding: 32px 0 16px;
  margin: 0 16px;

  background-color: ${(props) => props.theme.colors.grayscale.white};
  color: #a2a2a2;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    width: 100%;
    padding: 25px 16px;
    margin: 0;
    flex-direction: row;
    min-height: ${(props) => props.theme.navbar.height};
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    flex-direction: row;
  }
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;

  color: ${(props) => props.theme.colors.primary};

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    flex-direction: row;
  }
`

const Wrapper = styled.footer`
  position: relative;
  background-color: ${(props) => props.theme.colors.grayscale.white};
  width: 100%;
  min-height: 300px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    border-top: 1px solid #e5e3e4;
  }
`

const Menu = styled.div`
  width: ${(props) => (props.size ? props.size * (100 / 12) + "%" : "100%")};
  flex: 0 0 ${(props) => (props.size ? props.size * (100 / 12) + "%" : "100%")};
  width: 100%;
  padding: 0 16px 40px;

  &:first-of-type {
    padding-top: 40px;
    border-top: 1px solid #e5e3e4;

    ${(props) => props.theme.breakpointsLegacy.tablet} {
      border-top: 0;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding: 40px 16px;

    &:not(:last-of-type) {
      border-right: 1px solid #e5e3e4;
    }
  }
`

const MenuSection = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-size: 15px;

  .contact {
    margin-top: 12px;
    margin-bottom: 45px;

    a {
      text-decoration: none;
      letter-spacing: 1px;
    }
  }

  .newsletter {
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      max-width: 340px;
    }
  }
`

const MenuItem = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.primary};

  a {
    color: currentColor;
    text-decoration: none;
  }

  &:first-of-type {
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e3e4;
    padding-bottom: 16px;

    ${(props) => props.theme.breakpointsLegacy.tablet} {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  span {
    cursor: pointer;
  }
`

const LiveChatStatus = styled.span`
  color: #149d22;
  font-size: 10px;
  margin-top: 2px;
  margin-left: 9px;
`

const CountrySelector = styled.div`
  padding: 32px 0 25px;
  text-decoration: underline;
  border-top: 1px solid #e5e3e4;
  margin: 0 16px;
`

const Footer = () => {
  const locale = "en-US"
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()
  const { isActive: isLiveChatActive } = useIsZendeskLiveChatActive()

  const windowSize = useWindowSize()

  const { showRegion } = React.useContext(InterfaceContext)
  const { cart } = useStore()

  return (
    <StaticQuery
      query={graphql`
        {
          footerNav: allContentfulNavigationMenu(
            filter: { name: { eq: "New Footer Menu" } }
          ) {
            nodes {
              name
              node_locale
              items {
                ... on ContentfulNavigationItemTopLevelItem {
                  id
                  title
                  linkTo
                  items {
                    title
                    items {
                      isHeading
                      name
                      id
                      linkTo
                      reference {
                        ...SimplePage
                        ...SimpleBlogPost
                        ...AssetWrapper
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ footerNav }) => {
        return (
          <>
            <Wrapper>
              <ContentContainer>
                <Menu size={4}>
                  <MenuSection>
                    <div className="newsletter">
                      <NewsletterInput showDescription={true} source="archive_footer"/>
                    </div>
                  </MenuSection>
                </Menu>
                {footerNav.nodes
                  .find((node) => node.node_locale === locale)
                  ?.items.map((topLevelItem, index) => {
                    return (
                      <Menu size={2} key={`menu-column-${index}`}>
                        <MenuItem>{topLevelItem.title}</MenuItem>
                        {topLevelItem.items?.map((column) => {
                          return column.items?.map((menuItem) => {
                            return (
                              <MenuItem
                                key={`menu-item-${menuItem.id}`}
                                onClick={() =>
                                  "Live chat" === menuItem?.name &&
                                  setIsActivated(true)
                                }
                              >
                                {renderNavigationItem(
                                  menuItem.name,
                                  menuItem,
                                  locale,
                                  "footer"
                                )}
                                {"Live chat" === menuItem?.name &&
                                  isLiveChatActive && (
                                    <LiveChatStatus>(LIVE NOW)</LiveChatStatus>
                                  )}
                              </MenuItem>
                            )
                          })
                        })}
                      </Menu>
                    )
                  })}
                {isMobileWindow(windowSize.width) && (
                  <CountrySelector onClick={showRegion}>
                    {cart?.shipping_address?.country_code &&
                      cart?.region?.currency_code && (
                        <>
                          Country & Currency:
                          {` ${cart?.shipping_address.country_code.toUpperCase()} / ${cart?.region.currency_code.toUpperCase()}`}
                        </>
                      )}
                  </CountrySelector>
                )}
              </ContentContainer>
              <Bottom>
                <Container>
                  © {new Date().getFullYear()} Tekla. All rights reserved.
                </Container>
              </Bottom>
            </Wrapper>
            {isActivated && <ZendeskWidget />}
          </>
        )
      }}
    />
  )
}

export default Footer
