import * as React from "react"
import { Box } from "theme-ui"
import {
  useStripe,
  ExpressCheckoutElement,
  Elements,
  useElements,
} from "@stripe/react-stripe-js"
import {
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementClickEvent,
  ClickResolveDetails,
  StripeElementsOptionsMode,
} from "@stripe/stripe-js"
import getStripe from "../../../utils/get-stripe"

import { useStore } from "../../../context/NewStoreContext"
import {
  trackExpressCheckout,
  trackExpressCheckoutClick,
} from "../../../services/analytics"
import { hasAdyenEnabled } from "../utils/cart/hasAdyenEnabled"

const URL = process.env.GATSBY_URL || "http://localhost:8000"

const stripeStyleOptions: StripeElementsOptionsMode = {
  appearance: {
    variables: {
      borderRadius: "0",
    },
  },
}

const CheckoutElement = ({
  onPaymentCompleted,
  setCanMakePayment = undefined,
}) => {
  const stripe = useStripe()

  const { cart, updateCart } = useStore()
  const [errorMessage, setErrorMessage] = React.useState(null)
  const elements = useElements()

  const handlePrMethod = async (
    ev: StripeExpressCheckoutElementConfirmEvent
  ) => {
    let updatedCart = cart

    if (ev.billingDetails || ev.shippingAddress) {
      const firstName =
        ev.shippingAddress?.name.split(" ")[0] ||
        ev.billingDetails?.name.split(" ")[0]
      const lastName =
        ev.shippingAddress?.name.split(" ")[1] ||
        ev.billingDetails?.name.split(" ")[1]
      const country =
        ev.shippingAddress?.address?.country?.toLowerCase() ||
        updatedCart?.shipping_address?.country_code

      const res = await updateCart.mutateAsync({
        email: ev.billingDetails?.email,
        shipping_address: {
          city:
            ev.shippingAddress?.address.city || ev.billingDetails?.address.city,
          phone: ev.billingDetails?.phone,
          postal_code:
            ev.shippingAddress?.address.postal_code ||
            ev.billingDetails?.address.postal_code,
          first_name: firstName,
          last_name: lastName,
          address_1:
            ev.shippingAddress?.address.line1 ||
            ev.billingDetails?.address.line1,
          address_2:
            ev.shippingAddress?.address.line2 ||
            ev.billingDetails?.address.line2,
          country_code: country,
          province: ev.shippingAddress?.address.state,
        },
        billing_address: {
          city:
            ev.billingDetails?.address.city || ev.shippingAddress?.address.city,
          phone: ev.billingDetails?.phone,
          postal_code:
            ev.billingDetails?.address.postal_code ||
            ev.shippingAddress?.address.postal_code,
          first_name: firstName,
          last_name: lastName,
          address_1:
            ev.billingDetails?.address.line1 ||
            ev.shippingAddress?.address.line1,
          address_2:
            ev.billingDetails?.address.line2 ||
            ev.shippingAddress?.address.line2,
          country_code: country,
          province:
            ev.billingDetails?.address.state ||
            ev.shippingAddress?.address.state,
        },
      })

      if (res?.cart) {
        updatedCart = res.cart
      }
    }

    const { client_secret } = updatedCart.payment_session.data

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const stripeResponse = await stripe
      .confirmPayment({
        elements: elements,
        clientSecret: client_secret as string,
        redirect: "if_required",
        confirmParams: {
          return_url: `${URL}/checkout/payment?uct=${
            updatedCart?.id ? updatedCart.id : ""
          }`,
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (stripeResponse && stripeResponse?.error) {
      const pi = stripeResponse.error.payment_intent

      if (
        (pi && pi.status === "requires_capture") ||
        (pi && pi.status === "succeeded")
      ) {
        trackExpressCheckout(updatedCart?.id, ev.expressPaymentType)

        onPaymentCompleted(updatedCart)
      }

      setErrorMessage(stripeResponse.error.message)
    }

    if (
      stripeResponse &&
      stripeResponse?.paymentIntent &&
      (stripeResponse?.paymentIntent.status === "requires_capture" ||
        stripeResponse?.paymentIntent.status === "succeeded")
    ) {
      trackExpressCheckout(updatedCart?.id, ev.expressPaymentType)

      onPaymentCompleted(updatedCart)
    }
  }

  return (
    <ExpressCheckoutElement
      options={{
        buttonTheme: {
          applePay: "black",
          googlePay: "black",
        },
        buttonHeight: 44,
        layout: {
          maxRows: 0,
          overflow: "never",
        },
      }}
      onClick={({ resolve }: StripeExpressCheckoutElementClickEvent) => {
        if (cart.shipping_methods?.length) {
          trackExpressCheckoutClick(cart?.id)
          const options: ClickResolveDetails = {
            emailRequired: true,
            phoneNumberRequired: true,
            billingAddressRequired: true,
            shippingAddressRequired: true,
            shippingRates: [
              {
                id: cart.shipping_methods[0]?.id,
                displayName:
                  cart.shipping_methods[0]?.shipping_option?.name || "",
                amount: cart.shipping_methods[0]?.price,
              },
            ],
          }

          resolve(options)
        }
      }}
      onConfirm={handlePrMethod}
      onReady={() => {
        setCanMakePayment && setCanMakePayment(true)
      }}
    />
  )
}

const StripeExpressCheckout = ({
  onPaymentCompleted,
  setCanMakePayment = undefined,
}) => {
  const { cart, pay, startCheckout } = useStore()
  const [clientSecret, setClientSecret] = React.useState("")

  const stripeOptions: StripeElementsOptionsMode | {} =
    React.useMemo((): StripeElementsOptionsMode => {
      if (clientSecret && cart?.total && cart?.region?.currency_code) {
        return {
          ...stripeStyleOptions,
          mode: "payment",
          amount: cart.total,
          currency: cart.region.currency_code,
          capture_method: "manual",
          payment_method_types: ["card"],
        }
      }

      return {}
    }, [clientSecret, cart?.total, cart?.region?.currency_code])

  React.useEffect(() => {
    const setPaymentSession = async () => {
      let updatedCart = cart

      if (!cart?.payment_sessions?.length) {
        const res = await startCheckout.mutateAsync()

        if (res.cart) {
          updatedCart = res.cart
        }
      }

      if (!updatedCart?.payment_sessions?.length) {
        return
      }

      const stripeSession = cart?.payment_sessions?.find(
        (session) => session.provider_id === "stripe-express"
      )

      if (!stripeSession) {
        return
      }

      if (!clientSecret) {
        if (stripeSession?.data?.client_secret) {
          setClientSecret(stripeSession?.data?.client_secret as string)
        } else {
          const res = await pay?.mutateAsync({
            provider_id: "stripe-express",
          })

          if (res?.cart?.payment_session?.data?.client_secret) {
            setClientSecret(
              res.cart.payment_session.data.client_secret as string
            )
          }
        }
      }
    }

    if (
      cart?.id &&
      !pay?.isLoading &&
      !startCheckout?.isLoading &&
      !hasAdyenEnabled(cart)
    ) {
      setPaymentSession()
    }
  }, [cart])

  return clientSecret && stripeOptions ? (
    <Box
      sx={{
        marginBlockEnd: [4, 10],
      }}
    >
      <Elements stripe={getStripe()} options={stripeOptions}>
        <CheckoutElement
          onPaymentCompleted={onPaymentCompleted}
          setCanMakePayment={setCanMakePayment}
        />
      </Elements>
    </Box>
  ) : null
}

export default StripeExpressCheckout
