export const hasKlarna = (region: string) => {
  const klarnaRegions = [
    "at",
    "be",
    "ca",
    "dk",
    "fi",
    "de",
    "nl",
    "no",
    "pl",
    "se",
    "ch",
    "gb",
  ]

  return klarnaRegions.includes(region)
}
