import * as React from "react"
import styled from "@emotion/styled"
import { Link, navigate } from "gatsby"
import { Flex, Box } from "theme-ui"

import { AccountContext } from "../../context/AccountContext"
import { InterfaceContext } from "../../context/InterfaceContext"
import useWindowSize from "../../hooks/useWindowSize"
import useLockedBody from "../../hooks/v2/useLockedBody"
import useLockedBodyMobile from "../../hooks/v2/useLockedBodyMobile"
import getDisplayItems from "../../utils/get-display-items"
import { isDesktopWindow, isMobileWindow } from "../../utils/render-util"
import MenuWrapper from "../new-ui/product-menu"
import LoadingSpinner from "../ui/LoadingSpinner"
import { Cart } from "../v2/cart/Cart"
import { CartBag } from "./elements"
import LoginDrawer from "./LoginDrawer"
import RegionDrawer from "./RegionDrawer"
import useDrawerHook from "../../hooks/useDrawerHook"
import NoStoreModal from "../new-ui/modal/no-store-modal"

import BagIcon from "../../assets/svg/bag-icon.svg"
import { useStore } from "../../context/NewStoreContext"

import qs from "query-string"

const Account = styled.div`
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.hide &&
    `
  span {
    visibility: hidden;
  }
  `}
  .mobile {
    display: inline-block;
    font-size: 16px;
    position: relative;
    top: 0.5px;
  }
  .desktop {
    display: none;
  }
  .userIcon {
    position: relative;
    top: 2px;
  }
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    margin-left: 1rem;
    ${(props) => props.regionVisible && `flex: 1`};
    .desktop {
      display: inline-block;
    }
    .mobile {
      display: none;
    }
  }
`

const Region = styled.div`
  width: 150px;
  cursor: pointer;
`

const Header = ({
  navbarInWhite,
  withPromoElement,
  isHomepage,
  isProductsPage,
  openCart,
}) => {
  const {
    toggleRegionOpen,
    toggleSearchOpen,
    hideDrawers,
    isLoginOpen,
    toggleCart,
    isCartOpen,
    isRegionOpen,
    isSearchOpen,
    showCart,
  } = React.useContext(InterfaceContext)

  const { loggedIn, logOut, name } = React.useContext(AccountContext)
  const [isAccount, setIsAccount] = React.useState(false)

  const windowSize = useWindowSize()

  const [headerOptions, setHeaderOptions] = React.useState({
    scrollPosition: 0,
    scrollDirection: false,
    headerHasBackground: false,
    offcanvasIsOpen: false,
  })
  const { cart, setRegion, noStoreModal, setNoStoreModal } = useStore()
  const [locked, setLocked] = useLockedBody(false)
  const [lockedMobile, setLockedMobile] = useLockedBodyMobile(false)

  const handleScroll = () => {
    const currentPosition = window.scrollY

    if (
      currentPosition > headerOptions.scrollPosition &&
      currentPosition >= 50
    ) {
      setHeaderOptions({
        ...headerOptions,
        scrollDirection: "down",
        scrollPosition: currentPosition,
      })
    } else if (
      (currentPosition < headerOptions.scrollPosition &&
        currentPosition >= 50) ||
      (currentPosition > 0 && currentPosition < 50)
    ) {
      setHeaderOptions({
        ...headerOptions,
        scrollDirection: "up",
        scrollPosition: currentPosition,
      })
    }
  }

  React.useEffect(() => {
    const { share } = qs.parse(window.location.search)

    if ((share && typeof share === "string" && !isCartOpen) || openCart) {
      toggleCart()
    } else if (isCartOpen && !cart?.items?.length) {
      toggleCart()
    }
  }, [])

  React.useEffect(() => {
    if (isRegionOpen) {
      setHeaderOptions({
        ...headerOptions,
        headerHasBackground: true,
      })
    }
  }, [isRegionOpen])

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [headerOptions.scrollPosition])

  const handleSetCountry = (country) => {
    setRegion(country.region_id, country.country_code)
    toggleRegionOpen()
    isMobileWindow(windowSize.width) ? setLockedMobile(false) : setLocked(false)
    setHeaderOptions({ ...headerOptions, offcanvasIsOpen: false })
  }

  const [cartLoading, setCartLoading] = React.useState(true)

  const itemsInCart = React.useMemo(() => {
    const i = getDisplayItems(cart)
    return i.reduce((total, item) => total + item.quantity, 0)
  }, [cart])

  const { drawerContent, setDrawerContent, setDrawerVisible } = useDrawerHook({
    config: { asModal: true },
  })

  React.useEffect(() => {
    if (cart?.id != undefined && !noStoreModal) {
      setCartLoading(false)
    } else if (noStoreModal) {
      setDrawerContent(
        <NoStoreModal
          afterSubmit={() => {
            setDrawerVisible(false)
            setNoStoreModal(false)
          }}
        />
      )
      setDrawerVisible(true)
    }
  }, [cart, noStoreModal])

  const homeUrl = "/"

  const whiteNavbar =
    isHomepage &&
    navbarInWhite &&
    !isCartOpen &&
    !isRegionOpen &&
    !isSearchOpen &&
    withPromoElement

  React.useEffect(() => {
    if (isSearchOpen || isCartOpen) {
      setLocked(true)
    } else {
      setLocked(false)
    }
  }, [isSearchOpen, isCartOpen])

  React.useEffect(() => {
    setHeaderOptions({ ...headerOptions, scrollDirection: "up" })
  }, [locked, lockedMobile])

  return (
    <>
      {drawerContent}
      <Box
        onMouseOver={() => {
          if (isLoginOpen) {
            hideDrawers()
          }
        }}
        onClick={() => {
          if (isLoginOpen || isCartOpen || isRegionOpen) {
            hideDrawers()
          }
        }}
        sx={{
          width: "100%",
          height: "100%",
          opacity: isLoginOpen || isCartOpen || isRegionOpen ? 1 : 0,
          visibility:
            isLoginOpen || isCartOpen || isRegionOpen ? "visible" : "hidden",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "dismissCatch",
          backdropFilter: "blur(15px)",
          transition: "opacity .9s, visibility .9s",
        }}
      />
      <Flex
        onMouseOver={() =>
          isDesktopWindow(windowSize.width) &&
          setHeaderOptions({
            ...headerOptions,
            headerHasBackground: true,
          })
        }
        onMouseOut={() =>
          isDesktopWindow(windowSize.width) &&
          setHeaderOptions({
            ...headerOptions,
            headerHasBackground: false,
          })
        }
        sx={{
          position: "sticky",
          top: "0",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: 13,
          backgroundColor:
            headerOptions.headerHasBackground ||
              (isHomepage && !withPromoElement) ||
              isProductsPage ||
              (headerOptions.scrollDirection === "down" &&
                headerOptions.scrollPosition > 100) ||
              (headerOptions.scrollDirection === "up" &&
                headerOptions.scrollPosition > 1)
              ? "grayscale.100"
              : ["grayscale.100", "transparent"],
          zIndex: "header",
          paddingInline: 3,
          transform:
            headerOptions.scrollDirection === "down" && withPromoElement
              ? "translateY(calc(-100% - 34px))"
              : headerOptions.scrollDirection === "down"
                ? "translateY(-100%)"
                : "translateY(0)",
          transition: "transform .25s, background-color .25s",
          fontSize: "sm",
          color:
            (whiteNavbar &&
              !headerOptions.headerHasBackground &&
              !isMobileWindow(windowSize.width) &&
              headerOptions.scrollPosition < 1) ||
              (whiteNavbar &&
                headerOptions.scrollDirection === "down" &&
                headerOptions.scrollPosition < 100)
              ? "grayscale.white"
              : "grayscale.700",
        }}
      >
        {cartLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Region onClick={toggleRegionOpen} visible={isRegionOpen}>
              <Box sx={{ display: ["none", "block"] }}>
                {cart?.shipping_address?.country_code?.toUpperCase()}
                &#8201;/&#8201;
                {cart?.region?.currency_code?.toUpperCase()}
              </Box>
            </Region>
            <Box
              sx={{
                color:
                  (whiteNavbar &&
                    !headerOptions.headerHasBackground &&
                    !isMobileWindow(windowSize.width) &&
                    headerOptions.scrollPosition < 1) ||
                    (whiteNavbar &&
                      headerOptions.scrollDirection === "down" &&
                      headerOptions.scrollPosition < 100)
                    ? "grayscale.white"
                    : "grayscale.700",
                svg: { display: "block" },
              }}
            >
              <Link
                to={homeUrl || "/"}
                onClick={() => {
                  isSearchOpen && toggleSearchOpen()
                }}
              >
                {(whiteNavbar &&
                  !headerOptions.headerHasBackground &&
                  !isMobileWindow(windowSize.width) &&
                  headerOptions.scrollPosition < 1) ||
                  (whiteNavbar &&
                    headerOptions.scrollDirection === "down" &&
                    headerOptions.scrollPosition < 100) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="93"
                    fill="none"
                    viewBox="0 0 93 20"
                  >
                    <path
                      fill="#fff"
                      d="M0 0v6.26h4.19v13.13h7.37V6.26h4.2V0H0ZM18.28 0v19.39H32v-5.42h-6.64v-1.81h5.73V7.12h-5.73v-1.7h6.42V0h-13.5ZM45.79 0l-4.14 8.16h-.05V0h-7.08v19.39h7.08v-8.38h.05l4.76 8.38h7.74L47.94 9.4l5.4-9.4h-7.55ZM55.59 0v19.39h13.47v-6.36h-6.11V0h-7.36ZM81.38 5.41l1.46 5.74h-3l1.54-5.74ZM77.08 0l-6.5 19.38h7.11l.88-3.32h5.57l.85 3.32h7.39L85.8 0h-8.72Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="93"
                    fill="none"
                    viewBox="0 0 93 20"
                  >
                    <path
                      fill="#231F20"
                      d="M0 0v6.26h4.19v13.13h7.37V6.26h4.2V0H0ZM18.28 0v19.39H32v-5.42h-6.64v-1.81h5.73V7.12h-5.73v-1.7h6.42V0h-13.5ZM45.79 0l-4.14 8.16h-.05V0h-7.08v19.39h7.08v-8.38h.05l4.76 8.38h7.74L47.94 9.4l5.4-9.4h-7.55ZM55.59 0v19.39h13.47v-6.36h-6.11V0h-7.36ZM81.38 5.41l1.46 5.74h-3l1.54-5.74ZM77.08 0l-6.5 19.38h7.11l.88-3.32h5.57l.85 3.32h7.39L85.8 0h-8.72Z"
                    />
                  </svg>
                )}
              </Link>
            </Box>
            <Flex sx={{ width: "150px", justifyContent: "flex-end" }}>
              {isMobileWindow(windowSize.width) && (
                <Flex
                  sx={{
                    svg: {
                      "path, circle": {
                        stroke:
                          whiteNavbar &&
                            !headerOptions.headerHasBackground &&
                            !isMobileWindow(windowSize.width)
                            ? "grayscale.white"
                            : "grayscale.700",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: !isSearchOpen ? "block" : "none",
                      height: 4,
                    }}
                  ></Box>
                </Flex>
              )}
              {loggedIn && (
                <Account
                  regionVisible={isRegionOpen}
                  onClick={() => {
                    hideDrawers()
                    if (loggedIn) {
                      if (isAccount) {
                        logOut().then(() => {
                          navigate("/")
                        })
                      } else {
                        navigate("/account/welcome-back")
                      }
                    }
                  }}
                >
                  <span className="desktop">
                    {loggedIn ? (isAccount ? "Log out" : name) : "Login"}
                  </span>
                </Account>
              )}
              <CartBag onClick={showCart}>
                {!isMobileWindow(windowSize.width) ? "Cart" : <BagIcon />}{" "}
                {itemsInCart > 0 && <span>(&#8201;{itemsInCart}&#8201;)</span>}
              </CartBag>
            </Flex>
          </>
        )}
      </Flex>
      <MenuWrapper />
      <LoginDrawer visible={isLoginOpen} hideDrawers={hideDrawers} />
      {cart?.id ? <Cart isCartOpen={isCartOpen} /> : null}
      <RegionDrawer
        visible={isRegionOpen}
        onSetCountry={handleSetCountry}
        toggleRegionOpen={toggleRegionOpen}
        promotionalVisible={withPromoElement}
      />
    </>
  )
}

export default Header
