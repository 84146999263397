import * as React from "react"

// External packages
import { Cart as MedusaCart } from "@medusajs/medusa"
import { useCartShippingOptions, useProducts } from "medusa-react"
import { navigate } from "gatsby"
import { Box, Flex, Text, Heading, Paragraph } from "theme-ui"
import { capitalize } from "lodash"
import qs from "query-string"

// Contexts
import { useStore } from "../../../context/NewStoreContext"
import { useInterfaceContext } from "../../../context/InterfaceContext"
import { useAccountContext } from "../../../context/AccountContext"
import { useNotificationContext } from "../../../context/NotificationContext"

// Utilities
import getDisplayItems from "../../../utils/get-display-items"
import {
  getVariantsTotal,
  formatMoneyAmount,
  formatCartTotal,
  formatCartDiscount,
  formatCartSubtotal,
  formatPrices,
} from "../../../utils/prices"
import { calculateItemCount } from "../../../utils/item-count"
import { hasVariantGiftWrapping } from "../utils/product/hasVariantGiftWrapping"
import { hasCartSameProducts } from "../utils/cart/hasCartSameProducts"
import { isDesktopWindow, isMobileWindow } from "../../../utils/render-util"

// Hooks
import { useZendesk } from "../../../hooks/v2/useZendesk"
import useWindowSize from "../../../hooks/useWindowSize"

// Services
import {
  trackBundleRemoveFromCart,
  trackRemoveFromCart,
  trackCheckoutInitialized,
  trackCheckoutStepCompleted,
  trackCartShared,
} from "../../../services/analytics"

// Components
import PaymentOptions from "../../new-ui/payment-options"
import AdyenExpressCheckout from "../checkout/AdyenExpressCheckout"
import StripeExpressCheckout from "../checkout/StripeExpressCheckout"

import { Icon } from "../ui/Icon"
import { Divider } from "../ui/Divider"
import { CollapseOutside } from "../ui/CollapseOutside"
import { Input } from "../ui/Input"
import { CopyToClipboardInput } from "../ui/CopyToClipboardInput"
import { Button } from "../Button"
import { Link } from "../Link"
import { CartProduct } from "./CartProduct"

// Types
import type { RemoveItemProps, DisplayItem } from "types"
import { hasAdyenEnabled } from "../utils/cart/hasAdyenEnabled"
import { useCheckInventory } from "../../../hooks/v2/useCheckInventory"

const URL = process.env.GATSBY_URL || "http://localhost:8000"

export const Cart = ({ isCartOpen, children, ...flexProps }) => {
  const { toggleCart } = useInterfaceContext()
  const { id, addToWishList } = useAccountContext()
  const { pushNotification } = useNotificationContext()
  const footerAnchorRef = React.useRef<HTMLDivElement>(null)
  const [isShareCartVisible, setIsShareCartVisible] = React.useState(false)
  const [isGiftDiscountVisible, setIsGiftDiscountVisible] =
    React.useState(false)
  const [promoCodeOpen, setPromoCodeOpen] = React.useState(false)
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()
  const {
    cart,
    removeLineItem,
    freeShippingLimitThreshold,
    createLineItem,
    addShippingMethod,
    startCheckout,
    updateLineItem,
    updateCart,
    pay,
    isAboveFreeShippingLimit,
  } = useStore()
  const shippingOptions = useCartShippingOptions(cart?.id)
  const removingOptions: DisplayItem[] = JSON.parse(
    sessionStorage.getItem("removingOptions")
  )
  const {
    data: CheckInventoryData,
    isLoading: CheckInventoryDataLoading,
    refetch: refetchOOS,
  } = useCheckInventory("cart")
  const oosItems =
    CheckInventoryData?.step === "cart" || cart?.items?.length === 0
      ? CheckInventoryData?.oos_items
      : []
  const { products, isLoading } = useProducts(
    {
      id: cart?.items?.map((i) => i?.variant?.product_id),
      expand: "categories,type",
    },
    {
      enabled: Boolean(cart?.items?.length),
      keepPreviousData: true,
    }
  )
  const [canMakePayment, setCanMakePayment] = React.useState(false)
  const [code, setCode] = React.useState("")
  const [promoCodeError, setPromoCodeError] = React.useState("")
  const [paymentErrorMessage, setPaymentErrorMessage] = React.useState<
    string | null
  >(null)
  const [isPromoCodeAdded, setIsPromoCodeAdded] = React.useState(false)

  const distance = freeShippingLimitThreshold - getVariantsTotal(cart)

  const windowSize = useWindowSize()

  const itemCount = calculateItemCount(
    cart?.items?.filter((i) => !i.metadata?.is_wrapping)
  )

  const inclVat =
    cart?.shipping_address?.country_code?.toLowerCase() === "ch" ||
    cart?.shipping_address?.country_code?.toLowerCase() === "kr"

  const clear = (item: DisplayItem) => {
    if (removingOptions) {
      sessionStorage.setItem(
        "removingOptions",
        JSON.stringify(removingOptions.filter((i) => i.id !== item.id))
      )

      createLineItem.mutate({
        variant_id: item.variant_id,
        quantity: item.quantity,
      })
    }
  }

  React.useEffect(() => {
    if (isPromoCodeAdded) {
      setTimeout(() => {
        setIsPromoCodeAdded(false)
        setCode("")
        setIsGiftDiscountVisible(false)
      }, 5000)
    }
  }, [isPromoCodeAdded])

  const cheapestShippingOption = shippingOptions?.shipping_options?.sort(
    (a, b) => a.amount - b.amount
  )?.[0]

  React.useEffect(() => {
    const fn = async () => {
      const res = await shippingOptions.refetch()

      if (res?.data?.shipping_options?.length) {
        const lowestPriced = res.data.shipping_options
          ?.filter((o) => !o?.data?.require_drop_point)
          ?.sort((a, b) => a.amount - b.amount)[0]

        if (lowestPriced?.id && lowestPriced?.name !== "Digital Copy") {
          await addShippingMethod.mutateAsync({
            option_id: lowestPriced.id,
            data: lowestPriced.data,
          })
        }
      }
    }

    if (
      cart?.id &&
      cart?.total &&
      !shippingOptions.isLoading &&
      !addShippingMethod.isLoading
    ) {
      fn()
    }
  }, [cart?.total])

  React.useEffect(() => {
    const setPaymentSession = async () => {
      startCheckout.mutate(null, {
        onSuccess: (data) => {
          if (
            data.cart.payment_sessions.length &&
            data.cart.payment_sessions.some((ps) => ps.provider_id === "adyen")
          ) {
            pay.mutate({
              provider_id: "adyen",
            })
          }
        },
      })
    }

    if (
      cart?.id &&
      !pay?.isLoading &&
      !updateCart?.isLoading &&
      !startCheckout?.isLoading &&
      hasAdyenEnabled(cart)
    ) {
      setPaymentSession()
    }
  }, [cart])

  React.useEffect(() => {
    let { paymentRefused } = qs.parse(window.location.search)

    if (paymentRefused) {
      if (Array.isArray(paymentRefused)) {
        paymentRefused = paymentRefused[0]
      }

      setPaymentErrorMessage(paymentRefused)
    }
  }, [cart])

  React.useEffect(() => {
    setTimeout(() => {
      sessionStorage.removeItem("removingOptions")
    }, 1000)
  }, [isCartOpen])

  React.useEffect(() => {
    shippingOptions.refetch()
  }, [isAboveFreeShippingLimit])

  const handleRemove = ({
    id,
    ids,
    is_bundle,
    is_gifting,
    bundle,
    unit_price,
    variant,
    quantity,
    movingToWishlist = false,
  }: RemoveItemProps) => {
    const item = items.find((i) => i.id === id)

    const formattedTitle = capitalize(
      item.title
        .toLowerCase()
        .replace(` - ${item?.variant?.product?.subtitle?.toLowerCase()}`, "")
        .replace(` – ${item?.variant?.product?.subtitle?.toLowerCase()}`, "")
    )

    if (removingOptions) {
      sessionStorage.setItem(
        "removingOptions",
        JSON.stringify([
          ...removingOptions,
          {
            ...item,
            label: movingToWishlist
              ? `${formattedTitle} has been moved to the wish list`
              : `${formattedTitle} has been removed from the cart`,
            index: items.findIndex((i) => i.id === id),
            isRemoving: true,
          },
        ])
      )
    } else {
      sessionStorage.setItem(
        "removingOptions",
        JSON.stringify([
          {
            ...item,
            label: movingToWishlist
              ? `${formattedTitle} has been moved to the wish list`
              : `${formattedTitle} has been removed from the cart`,
            index: items.findIndex((i) => i.id === id),
            isRemoving: true,
          },
        ])
      )
    }

    if (is_bundle) {
      trackBundleRemoveFromCart(cart, bundle)
      for (const id of ids) {
        removeLineItem.mutate({ lineId: id })
      }
    } else if (is_gifting) {
      removeLineItem.mutate({ lineId: id })
    } else {
      trackRemoveFromCart(cart, variant.product, variant, quantity, unit_price)
      removeLineItem.mutate({ lineId: id })
    }
  }

  const handleAddToWishlist = (item) => {
    if (id) {
      addToWishList({
        variantId: item.variant.id,
        metadata: {
          product_handle: item.variant.product.handle,
        },
        config: {
          userId: id,
        },
      })
        .then(() => {
          pushNotification({
            id: "wishlist-added",
            body: `${item.variant.product.title} ${item.description} added to wishlist`,
            dismiss: {
              duration: 5000,
            },
          })

          handleRemove({
            id: item.id,
            ids: item.ids,
            variant: item.variant,
            quantity: item.quantity,
            unit_price: item.original_total,
            is_bundle: item.is_bundle,
            bundle: item,
            movingToWishlist: true,
          })
        })
        .catch(() => {
          pushNotification({
            id: "wishlist-rejected",
            body: `${item.variant.product.title} ${item.description} already added`,
            dismiss: {
              duration: 5000,
            },
          })
        })

      return
    }

    let wishlist = JSON.parse(localStorage.getItem("medusa::cache::wishlist"))

    if (!wishlist) {
      wishlist = []
    }

    if (!wishlist.map((item) => item.variantId).includes(item.variant.id)) {
      wishlist.push({
        variantId: item.variant.id,
        metadata: {
          product_handle: item.variant.product.handle,
        },
      })

      localStorage.setItem("medusa::cache::wishlist", JSON.stringify(wishlist))

      pushNotification({
        id: "wishlist-added",
        body: `${item.variant.product.title} ${item.description} added to wishlist`,
        dismiss: {
          duration: 5000,
        },
      })

      handleRemove({
        id: item.id,
        ids: item.ids,
        variant: item.variant,
        quantity: item.quantity,
        unit_price: item.original_total,
        is_bundle: item.is_bundle,
        bundle: item,
        movingToWishlist: true,
      })
    } else {
      pushNotification({
        id: "wishlist-rejected",
        body: `${item.variant.product.title} ${item.description} already added`,
        dismiss: {
          duration: 5000,
        },
      })
    }
  }

  let items = getDisplayItems(cart).sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  })

  if (removingOptions) {
    removingOptions.forEach((item) => {
      if (items.every((i) => i.id !== item.id)) {
        items.splice(item.index, 0, {
          ...item,
        })
      }
    })
  }

  const handlePaymentCompleted = (
    updatedCart: Omit<MedusaCart, "refundable_amount" | "refunded_total">
  ) => {
    if (updatedCart?.id) {
      trackCheckoutStepCompleted(updatedCart, 1)
      trackCheckoutStepCompleted(updatedCart, 2)
      trackCheckoutStepCompleted(updatedCart, 3)
    }

    navigate(`/checkout/payment?uct=${updatedCart?.id ? updatedCart.id : ""}`)
  }

  const handleAddPromoCode = async () => {
    let error
    const giftCardCodes = cart.gift_cards
      .filter((gc) => gc.code !== code)
      .map((giftCard) => ({
        code: giftCard.code,
      }))
    await updateCart
      .mutateAsync({
        gift_cards: [
          ...giftCardCodes,
          {
            code: code,
          },
        ],
      })
      .catch((err) => (error = err.message))

    if (error) {
      setPromoCodeError("Code not valid")
    } else {
      setIsPromoCodeAdded(true)
      setPromoCodeOpen(false)
      setPromoCodeError("")

      if (cart?.payment_session?.provider_id === "paypal") {
        pay.mutateAsync({
          provider_id: "paypal",
        })
      }
    }
  }

  const handleRemovePromoCode = async (code: string) => {
    const discounts = cart.discounts.filter(
      (discount) => discount.code !== code
    )

    await updateCart.mutateAsync({
      discounts,
    })
  }

  const handleRemoveGiftCard = async (code: string) => {
    const giftCardCodes = cart.gift_cards.filter(
      (gift_card) => gift_card.code !== code
    )

    await updateCart.mutateAsync({
      gift_cards: giftCardCodes.map((giftCard) => ({
        code: giftCard.code,
      })),
    })
  }

  const hasGiftWrappingMessage = (cart: Readonly<MedusaCart>): boolean => {
    return cart?.items.some((item) => hasVariantGiftWrapping(item?.variant))
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        position: "fixed",
        top: 0,
        right: 0,
        width: 157,
        maxWidth: "100%",
        height: "100vh",
        maxHeight: "100dvh",
        fontSize: "sm",
        backgroundColor: "grayscale.white",
        zIndex: "cart",
        paddingInline: [4, 15],
        overflowY: ["scroll", "visible"],
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        transform: !isCartOpen && "translateX(100%)",
        transition: "transform cubic-bezier(.5, .5, 0, 1) .9s",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
      {...flexProps}
    >
      {(items?.length && !isLoading) ||
      (oosItems?.length && !CheckInventoryDataLoading) ||
      createLineItem?.isLoading ? (
        <>
          <Box
            sx={{
              flex: 1,
              overflowY: [null, "scroll"],
              msOverflowStyle: "none",
              marginBlockEnd: "-1px",
              marginInline: [null, -15],
              paddingInline: [null, 15],
            }}
          >
            <Flex
              sx={{
                alignItems: "flex-end",
                justifyContent: "space-between",
                marginBlockEnd: 8,
              }}
            >
              <Box sx={{ marginBlockStart: [4, 8] }}>
                <Heading>Cart</Heading>
                <Paragraph>
                  {distance <= 0
                    ? "You qualify for free shipping!"
                    : `${formatMoneyAmount({
                        amount: distance,
                        currencyCode: cart?.region?.currency_code,
                      })} away from free shipping`}
                </Paragraph>
              </Box>
              <Box>
                <Link onClick={() => setIsShareCartVisible((val) => !val)}>
                  Share cart
                </Link>
              </Box>
            </Flex>
            <Heading
              as="h3"
              sx={{
                width: "100%",
                fontSize: "base",
                borderBlockEnd: "1px solid",
                borderBlockEndColor: "grayscale.100",
                paddingBlockEnd: 4,
              }}
            >
              <Paragraph>{`Order summary (${itemCount || 0}) item${
                itemCount > 1 ? "s" : ""
              }`}</Paragraph>
            </Heading>
            {items
              .filter((item) => Boolean(item.variant))
              .map((item: DisplayItem, index) => {
                const oosItem = oosItems?.find(
                  (i) => i.variant.id === item.variant.id
                )
                if (
                  oosItem?.variant &&
                  oosItem.variant.inventory_quantity < 1
                ) {
                  return null
                }

                return (
                  <CartProduct
                    item={item}
                    medusaProduct={products?.find(
                      (p) => p.id === item.variant.product_id
                    )}
                    hasQuantityChanged={
                      oosItem?.variant?.inventory_quantity > 0 &&
                      oosItem.quantity_before >
                        oosItem.variant.inventory_quantity
                    }
                    onRemoveLine={handleRemove}
                    onAddToWishlist={handleAddToWishlist}
                    clear={clear}
                    color={item.variant?.product?.subtitle}
                    key={item.id}
                    sx={{ marginBlockEnd: items.length - 1 === index && 4 }}
                  />
                )
              })}
            {oosItems?.some((item) => item.variant.inventory_quantity < 1) &&
            oosItems.length ? (
              <Box sx={{ marginBlockStart: -4, marginBlockEnd: 4 }}>
                {oosItems.map((item, index) => {
                  if (
                    item.variant.inventory_quantity > 0 &&
                    item.quantity_before > item.variant.inventory_quantity
                  ) {
                    return
                  }

                  return (
                    <CartProduct
                      item={item}
                      medusaProduct={item}
                      isSoldOut
                      onRemoveLine={handleRemove}
                      onAddToWishlist={handleAddToWishlist}
                      clear={clear}
                      color={item?.subtitle}
                      key={item.id}
                      sx={{
                        marginBlockEnd: oosItems.length - 1 === index && 4,
                      }}
                      onOOSRemove={() => {
                        let oos = oosItems.filter(
                          (i) => i.variant.id !== item.variant.id
                        )
                        sessionStorage.setItem(
                          "tekla::cart:oss",
                          JSON.stringify({
                            ...CheckInventoryData,
                            oos_items: oos,
                          })
                        )
                        refetchOOS()
                      }}
                    />
                  )
                })}
              </Box>
            ) : null}
            {hasCartSameProducts(cart) && (
              <>
                <Paragraph sx={{ fontSize: "xs", marginBlockEnd: 4 }}>
                  Need help?{" "}
                  <Link
                    variant="underline"
                    onClick={() => setIsActivated(true)}
                  >
                    Contact us
                  </Link>
                </Paragraph>
                <Divider
                  sx={{
                    order: 4,
                    marginBlockEnd: 4,
                    backgroundColor: "grayscale.100",
                  }}
                />
              </>
            )}
            {/* ↓ Show the "Gift card / Promo code" input field when a product
                  marked as `allow_discounts` is present in the cart. */}
            {cart?.items?.some((e) => e.allow_discounts === true) && (
              <>
                <Paragraph
                  sx={{
                    display: ["flex", "none"],
                    fontSize: "xs",
                    color: "grayscale.700",
                    cursor: "pointer",
                    marginBlockEnd: 4,
                  }}
                  onClick={() => setIsGiftDiscountVisible(true)}
                >
                  <Text
                    sx={{
                      display: "inline-block",
                      marginInlineEnd: 2,
                    }}
                  >
                    +
                  </Text>
                  Add gift card
                </Paragraph>
                <Box sx={{ display: ["none", "block"] }}>
                  <CollapseOutside
                    labelWhenOpened="Show less"
                    labelWhenClosed="Add gift card"
                    labelPosition="end"
                    isOpen={promoCodeOpen}
                    setIsOpen={setPromoCodeOpen}
                    sx={{ marginBlockEnd: 4 }}
                  >
                    <Box sx={{ paddingBlockEnd: 3 }}>
                      <Flex>
                        <Input
                          placeholder="Gift card"
                          type="text"
                          hasFloatingLabel
                          sx={{ flex: 1 }}
                          hasError={Boolean(promoCodeError)}
                          onChange={(e) => setCode(e.target.value)}
                          value={code}
                        />
                        <Button
                          onClick={handleAddPromoCode}
                          isLoading={updateCart?.isLoading}
                          sx={{ width: [28, 40], height: "auto" }}
                        >
                          Add
                        </Button>
                      </Flex>
                      {promoCodeError && (
                        <Paragraph
                          sx={{
                            fontSize: "xs",
                            marginBlockStart: 1,
                            color: "red",
                          }}
                        >
                          {promoCodeError}
                        </Paragraph>
                      )}
                    </Box>
                  </CollapseOutside>
                </Box>
                <Divider
                  sx={{
                    order: 4,
                    marginBlockEnd: 4,
                    backgroundColor: "grayscale.100",
                  }}
                />
              </>
            )}
            {hasGiftWrappingMessage(cart) && (
              <Box
                sx={{
                  display: ["none", "flex"],
                  fontSize: "xs",
                  justifyContent: "space-between",
                  marginBlockEnd: 3,
                }}
              >
                <Paragraph>Gift wrapping</Paragraph>
                <Paragraph>available at checkout</Paragraph>
              </Box>
            )}
            <>
              <Box
                sx={{
                  display: ["none", "flex"],
                  fontSize: "xs",
                  justifyContent: "space-between",
                  marginBlockEnd: 3,
                }}
              >
                <Paragraph>
                  {cart?.region?.tax_rate === 0 && !inclVat
                    ? "Subtotal"
                    : "Subtotal (incl. VAT)"}
                </Paragraph>
                <Paragraph>{formatCartSubtotal(cart)}</Paragraph>
              </Box>
              {!!cart?.discount_total && cart?.discounts?.length
                ? cart.discounts.map((discountCode) => (
                    <Box
                      sx={{
                        fontSize: "xs",
                        display: ["none", "flex"],
                        justifyContent: "space-between",
                        marginBlockEnd: 3,
                      }}
                    >
                      <Flex sx={{ flexDirection: "column" }}>
                        <Paragraph
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Text>
                            -
                            {discountCode.rule?.type === "percentage"
                              ? `${discountCode.rule?.value}%`
                              : formatPrices(
                                  cart,
                                  discountCode.rule?.value || 0,
                                  2
                                )}
                          </Text>
                        </Paragraph>
                      </Flex>
                      <Paragraph sx={{ marginInlineStart: "auto" }}>
                        - {formatCartDiscount(cart)}
                      </Paragraph>
                    </Box>
                  ))
                : null}
              <Box
                sx={{
                  display: ["none", "flex"],
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBlockEnd: 3,
                }}
              >
                <Paragraph sx={{ fontSize: "xs" }}>
                  Estimated shipping cost
                </Paragraph>
                {cart?.shipping_methods?.length > 0 && (
                  <Paragraph sx={{ fontSize: "xs" }}>
                    {cart?.shipping_methods?.[0]?.total === 0
                      ? "Free"
                      : formatMoneyAmount({
                          amount:
                            cart?.region?.name === "United States"
                              ? cart?.shipping_methods?.[0]?.subtotal
                              : cheapestShippingOption?.amount,
                          currencyCode: cart?.region?.currency_code,
                        })}
                  </Paragraph>
                )}
              </Box>
              {!!cart?.gift_card_total && cart?.gift_cards?.length
                ? cart.gift_cards?.map((giftCard) => (
                    <Box
                      sx={{
                        fontSize: "xs",
                        display: ["none", "flex"],
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBlockEnd: 3,
                      }}
                    >
                      <Flex>
                        <Paragraph>Gift card</Paragraph>
                        <Paragraph
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            marginInlineStart: 4,
                          }}
                        >
                          <Text>{giftCard.code}</Text>
                          <Link
                            onClick={() => handleRemoveGiftCard(giftCard.code)}
                            sx={{ color: "primary" }}
                          >
                            <Icon name="x" size={4} />
                          </Link>
                        </Paragraph>
                      </Flex>
                      <Paragraph>
                        -{" "}
                        {formatMoneyAmount({
                          currencyCode: cart.region.currency_code,
                          amount:
                            giftCard.balance * (giftCard.tax_rate / 100 + 1),
                        })}
                      </Paragraph>
                    </Box>
                  ))
                : null}
            </>
            {cart?.region?.countries[0]?.iso_2?.toLowerCase() === "us" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "jp" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "au" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "nz" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "ca" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "mo" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "sa" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "ae" ||
            cart?.shipping_address?.country_code?.toLowerCase() === "hk" ? (
              <Box
                sx={{
                  display: ["none", "flex"],
                  justifyContent: "space-between",
                  marginBlockEnd: 3,
                }}
              >
                <Paragraph sx={{ fontSize: "xs", marginInlineEnd: 3 }}>
                  Tax
                </Paragraph>
                {cart?.region?.name === "United States" &&
                cart?.tax_total !== 0 ? (
                  <Paragraph sx={{ fontSize: "xs", color: "grayscale.600" }}>
                    {formatPrices(cart, cart?.tax_total, 2)}
                  </Paragraph>
                ) : (
                  <Paragraph sx={{ fontSize: "xs", color: "grayscale.600" }}>
                    To be determined
                  </Paragraph>
                )}
              </Box>
            ) : null}
            {cart?.region?.payment_providers?.length > 0 &&
              cart?.items?.length > 0 && (
                <Box
                  sx={{
                    display: ["none", "flex"],
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBlockEnd: [10, 5],
                  }}
                >
                  <Paragraph sx={{ fontSize: "xs" }}>Safe payment</Paragraph>
                  <Box>
                    <Flex sx={{ gap: 3, alignItems: "center" }}>
                      <PaymentOptions
                        options={
                          cart?.region?.payment_providers.map(({ id }) => id) ||
                          []
                        }
                      />
                    </Flex>
                  </Box>
                </Box>
              )}
          </Box>
          <Box sx={{ display: [null, "none"], paddingBlock: 1 }}>
            {hasGiftWrappingMessage(cart) && (
              <Flex
                sx={{
                  fontSize: "xs",
                  justifyContent: "space-between",
                  marginBlockEnd: 3,
                }}
              >
                <Paragraph>Gift wrapping</Paragraph>
                <Paragraph>available at checkout</Paragraph>
              </Flex>
            )}
            <Flex
              sx={{
                fontSize: "xs",
                justifyContent: "space-between",
                marginBlockEnd: 3,
              }}
            >
              <Paragraph>
                {cart?.region?.tax_rate === 0 && !inclVat
                  ? "Subtotal"
                  : "Subtotal (incl. VAT)"}
              </Paragraph>
              <Paragraph>{formatCartSubtotal(cart)}</Paragraph>
            </Flex>
            {!!cart?.discount_total && cart?.discounts?.length
              ? cart.discounts.map((discountCode) => (
                  <Flex
                    sx={{
                      fontSize: "xs",
                      justifyContent: "space-between",
                      marginBlockEnd: 3,
                    }}
                  >
                    <Flex sx={{ flexDirection: "column" }}>
                      <Flex
                        sx={{
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Text>
                          -
                          {discountCode.rule?.type === "percentage"
                            ? `${discountCode.rule?.value}%`
                            : formatPrices(
                                cart,
                                discountCode.rule?.value || 0,
                                2
                              )}
                        </Text>
                        <Link
                          onClick={() =>
                            handleRemovePromoCode(discountCode.code)
                          }
                          sx={{ color: "primary" }}
                        >
                          <Icon name="x" size={4} />
                        </Link>
                      </Flex>
                    </Flex>
                    <Paragraph sx={{ marginInlineStart: "auto" }}>
                      - {formatCartDiscount(cart)}
                    </Paragraph>
                  </Flex>
                ))
              : null}

            <>
              <Flex sx={{ justifyContent: "space-between", marginBlockEnd: 3 }}>
                <Paragraph sx={{ fontSize: "xs", marginInlineEnd: 3 }}>
                  Estimated shipping cost
                </Paragraph>
                {cart?.shipping_methods?.length > 0 && (
                  <Paragraph sx={{ fontSize: "xs" }}>
                    {cart?.shipping_methods?.[0]?.total === 0
                      ? "Free"
                      : formatMoneyAmount({
                          amount:
                            cart?.region?.name === "United States"
                              ? cart?.shipping_methods?.[0]?.subtotal
                              : cheapestShippingOption?.amount,
                          currencyCode: cart?.region?.currency_code,
                        })}
                  </Paragraph>
                )}
              </Flex>
            </>

            {!!cart?.gift_card_total && cart?.gift_cards?.length
              ? cart.gift_cards?.map((giftCard) => (
                  <Flex
                    sx={{
                      fontSize: "xs",
                      justifyContent: "space-between",
                      marginBlockEnd: 3,
                    }}
                  >
                    <Flex>
                      <Paragraph>Gift card</Paragraph>
                      <Paragraph
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          marginInlineStart: 4,
                        }}
                      >
                        <Text>{giftCard.code}</Text>
                        <Link
                          onClick={() => handleRemoveGiftCard(giftCard.code)}
                          sx={{ color: "primary" }}
                        >
                          <Icon name="x" size={4} />
                        </Link>
                      </Paragraph>
                    </Flex>
                    <Paragraph>
                      -{" "}
                      {formatMoneyAmount({
                        currencyCode: cart.region.currency_code,
                        amount:
                          giftCard.balance * (giftCard.tax_rate / 100 + 1),
                      })}
                    </Paragraph>
                  </Flex>
                ))
              : null}
            {cart?.region?.countries[0]?.iso_2?.toLowerCase() === "us" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "jp" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "au" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "nz" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "ca" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "mo" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "sa" ||
            cart?.region?.countries[0]?.iso_2?.toLowerCase() === "ae" ||
            cart?.shipping_address?.country_code?.toLowerCase() === "hk" ? (
              <Flex sx={{ justifyContent: "space-between", marginBlockEnd: 3 }}>
                <Paragraph sx={{ fontSize: "xs", marginInlineEnd: 3 }}>
                  Tax
                </Paragraph>
                <Paragraph sx={{ fontSize: "xs" }}>
                  Local taxes and duties may apply
                </Paragraph>
              </Flex>
            ) : null}
            {cart?.region?.payment_providers?.length > 0 && (
              <Flex sx={{ justifyContent: "space-between" }}>
                <Paragraph
                  sx={{ fontSize: "xs", flexShrink: 0, marginInlineEnd: 3 }}
                >
                  Safe payment
                </Paragraph>
                <Flex
                  sx={{
                    gap: 2,
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                  }}
                >
                  <PaymentOptions
                    options={
                      cart?.region?.payment_providers.map(({ id }) => id) || []
                    }
                  />
                </Flex>
              </Flex>
            )}
          </Box>
          <Flex
            sx={{
              backgroundColor: "grayscale.white",
              position: "sticky",
              bottom: 0,
              flexDirection: "column",
              gap: [2, 4],
              paddingBlockEnd: [2, 4],
              paddingBlockStart: [5, 6],
            }}
          >
            {cart?.items?.length > 0 &&
            (removingOptions?.length === undefined ||
              removingOptions?.length > 0) ? (
              <Button
                subLabel={
                  <>
                    {cart?.region?.countries[0]?.iso_2?.toLowerCase() ===
                      "us" && "Estimated total: "}
                    <Text sx={{ fontWeight: "bold" }}>
                      {cart.total <= 0
                        ? formatCartTotal({ ...cart, total: 0 })
                        : formatCartTotal(cart)}
                    </Text>
                  </>
                }
                sx={{
                  width: "100%",
                  marginBlockStart: 5,
                  marginBlockEnd: [2, 4],
                }}
                onClick={() => {
                  trackCheckoutInitialized(cart)
                  sessionStorage.removeItem("tekla::cart:oss")
                  refetchOOS()
                  toggleCart()
                  navigate("/checkout")
                }}
                isLoading={
                  startCheckout.isLoading ||
                  createLineItem.isLoading ||
                  removeLineItem.isLoading ||
                  updateLineItem.isLoading ||
                  addShippingMethod.isLoading ||
                  updateCart.isLoading
                }
                isVisuallyDisabled={
                  startCheckout.isLoading ||
                  createLineItem.isLoading ||
                  removeLineItem.isLoading ||
                  updateLineItem.isLoading ||
                  addShippingMethod.isLoading ||
                  updateCart.isLoading
                }
                disabled={
                  startCheckout.isLoading ||
                  createLineItem.isLoading ||
                  removeLineItem.isLoading ||
                  updateLineItem.isLoading ||
                  addShippingMethod.isLoading ||
                  updateCart.isLoading
                }
              >
                Checkout
              </Button>
            ) : (
              <Button sx={{ width: "100%" }} onClick={toggleCart}>
                Continue shopping
              </Button>
            )}
            {isMobileWindow(windowSize.width) && (
              <>
                {paymentErrorMessage && (
                  <Paragraph
                    sx={{
                      fontSize: "xxs",
                      marginBlockStart: 1,
                      color: "red",
                    }}
                  >
                    {paymentErrorMessage === "12" ? (
                      <>
                        We failed to process your payment due to insufficient
                        funds. Please try again with a different payment method
                        or contact{" "}
                        <Link
                          onClick={() => setIsActivated(true)}
                          sx={{ textDecoration: "underline" }}
                        >
                          customer service
                        </Link>{" "}
                        for help
                      </>
                    ) : (
                      <>
                        We failed to process your payment. Please try again. If
                        the issue continues, contact{" "}
                        <Link
                          onClick={() => setIsActivated(true)}
                          sx={{ textDecoration: "underline" }}
                        >
                          customer service
                        </Link>{" "}
                        for help
                      </>
                    )}
                  </Paragraph>
                )}
                <Paragraph sx={{ color: "grayscale.600", fontSize: "xxs" }}>
                  By placing an order, you accept the{" "}
                  <Link
                    variant="underline"
                    to="/terms-and-conditions"
                    onClick={toggleCart}
                    sx={{ borderBlockEndColor: "grayscale.400" }}
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and confirm you have read the{" "}
                  <Link
                    variant="underline"
                    to="/privacy-policy"
                    onClick={toggleCart}
                    sx={{ borderBlockEndColor: "grayscale.400" }}
                  >
                    Privacy Policy
                  </Link>
                </Paragraph>
              </>
            )}
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              backgroundColor: "grayscale.white",
              gap: [2, 4],
              paddingBlockEnd: [5, 6],
            }}
          >
            {canMakePayment && itemCount > 2 && (
              <Paragraph
                sx={{
                  display: [null, "none"],
                  color: "grayscale.600",
                  a: { color: "grayscale.600" },
                }}
              >
                or{" "}
                <Link
                  variant="underline"
                  onClick={() => {
                    footerAnchorRef.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                    })
                  }}
                >
                  Express checkout
                </Link>
              </Paragraph>
            )}
            {hasAdyenEnabled(cart) ? (
              cart.context?.split_order ? null : (
                <AdyenExpressCheckout
                  setErrorMessage={setPaymentErrorMessage}
                />
              )
            ) : cart?.total > 0 ? (
              <StripeExpressCheckout
                onPaymentCompleted={handlePaymentCompleted}
                setCanMakePayment={setCanMakePayment}
              />
            ) : null}
            {isDesktopWindow(windowSize.width) && (
              <>
                {paymentErrorMessage && (
                  <Paragraph
                    sx={{
                      fontSize: "xxs",
                      marginBlockStart: 1,
                      color: "red",
                    }}
                  >
                    {paymentErrorMessage === "12" ? (
                      <>
                        We failed to process your payment due to insufficient
                        funds. Please try again with a different payment method
                        or contact{" "}
                        <Link
                          onClick={() => setIsActivated(true)}
                          sx={{ textDecoration: "underline" }}
                        >
                          customer service
                        </Link>{" "}
                        for help
                      </>
                    ) : (
                      <>
                        We failed to process your payment. Please try again. If
                        the issue continues, contact{" "}
                        <Link
                          onClick={() => setIsActivated(true)}
                          sx={{ textDecoration: "underline" }}
                        >
                          customer service
                        </Link>{" "}
                        for help
                      </>
                    )}
                  </Paragraph>
                )}
                <Paragraph sx={{ color: "grayscale.600", fontSize: "xxs" }}>
                  By placing an order, you accept the{" "}
                  <Link
                    variant="underline"
                    to="/terms-and-conditions"
                    onClick={toggleCart}
                    sx={{ borderBlockEndColor: "grayscale.400" }}
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and confirm you have read the{" "}
                  <Link
                    variant="underline"
                    to="/privacy-policy"
                    onClick={toggleCart}
                    sx={{ borderBlockEndColor: "grayscale.400" }}
                  >
                    Privacy Policy
                  </Link>
                </Paragraph>
              </>
            )}
          </Flex>
          <Flex
            sx={{
              opacity: isShareCartVisible ? 1 : 0,
              visibility: isShareCartVisible ? "visible" : "hidden",
              alignItems: "center",
              position: "fixed",
              top: 0,
              right: 0,
              width: 157,
              maxWidth: "100%",
              height: [null, "100%"],
              backgroundColor: "grayscale.white",
              zIndex: "shareCart",
              transform: isShareCartVisible
                ? ["translateY(0)", "unset"]
                : ["translateY(-100%)", "unset"],
              transition: "opacity .2s, visibility .2s, transform .2s",
              paddingInline: [4, 16],
              paddingBlock: [4, 0],
              paddingBlockStart: [4, 0],
              paddingBlockEnd: [6, 0],
            }}
          >
            {cart?.id && (
              <Box sx={{ flex: 1 }}>
                <Heading sx={{ fontSize: "xl", marginBlockEnd: 4 }}>
                  Share your cart
                </Heading>
                <Paragraph sx={{ marginBlockEnd: [6, 10] }}>
                  Copy link below to share the cart
                </Paragraph>
                <CopyToClipboardInput
                  value={`${URL}?share=${cart?.id}`}
                  onClick={() => {
                    trackCartShared(cart?.id)
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                color: "grayscale.700",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 6,
                height: 6,
                position: "fixed",
                top: 4,
                right: 4,
                cursor: "pointer",

                svg: {
                  width: [6, 5],
                },
              }}
              onClick={() => setIsShareCartVisible((val) => !val)}
            >
              <Icon name="x" />
            </Box>
          </Flex>
          <Flex
            sx={{
              opacity: isGiftDiscountVisible ? 1 : 0,
              visibility: isGiftDiscountVisible ? "visible" : "hidden",
              alignItems: "center",
              position: "fixed",
              top: 0,
              right: 0,
              width: 157,
              maxWidth: "100%",
              height: [null, "100%"],
              backgroundColor: "grayscale.white",
              zIndex: "shareCart",
              transform: isGiftDiscountVisible
                ? "translateY(0)"
                : "translateY(-100%)",
              transition: "opacity .2s, visibility .2s, transform .2s",
              paddingInline: [4, 16],
              paddingBlock: [4, 0],
              paddingBlockStart: [4, 0],
              paddingBlockEnd: [6, 0],
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Heading sx={{ fontSize: "h6", marginBlockEnd: 7 }}>
                {isPromoCodeAdded
                  ? "Gift card successfully added!"
                  : "Add gift card"}
              </Heading>
              <Flex sx={{ marginBlockEnd: 2 }}>
                <Input
                  placeholder="Gift card"
                  type="text"
                  sx={{ flex: 1 }}
                  hasError={Boolean(promoCodeError)}
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  disabled={isPromoCodeAdded}
                />
                <Button
                  onClick={handleAddPromoCode}
                  isLoading={updateCart?.isLoading}
                  isSuccessful={isPromoCodeAdded}
                  disabled={isPromoCodeAdded}
                  sx={{ width: [28, 40], height: "auto" }}
                >
                  Add
                </Button>
              </Flex>
              {promoCodeError && (
                <Paragraph
                  sx={{
                    fontSize: "xs",
                    marginBlockStart: 1,
                    color: "red",
                  }}
                >
                  {promoCodeError}
                </Paragraph>
              )}
            </Box>
            <Box
              sx={{
                color: "grayscale.700",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 6,
                height: 6,
                position: "fixed",
                top: 4,
                right: 4,
                cursor: "pointer",

                svg: {
                  width: [6, 5],
                },
              }}
              onClick={() => setIsGiftDiscountVisible((val) => !val)}
            >
              <Icon name="x" />
            </Box>
          </Flex>
          <Box
            sx={{
              display: ["block", "none"],
              opacity: isShareCartVisible || isGiftDiscountVisible ? 1 : 0,
              visibility:
                isShareCartVisible || isGiftDiscountVisible
                  ? "visible"
                  : "hidden",
              width: 157,
              height: "100%",
              position: "fixed",
              top: 0,
              right: 0,
              backdropFilter: "blur(15px)",
              transition: "opacity .2s, visibility .2s",
            }}
            onClick={() => setIsShareCartVisible(false)}
          />
        </>
      ) : (
        <Flex
          sx={{
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginBlock: "auto" }}>
            <Text sx={{ fontSize: ["lg", "h6"] }}>Your cart is empty.</Text>
          </Box>
          <Box sx={{ width: "100%", marginBlockEnd: [10, 15] }}>
            <Button sx={{ width: "100%" }} onClick={toggleCart}>
              Continue shopping
            </Button>
          </Box>
        </Flex>
      )}
      <Box
        sx={{
          color: "grayscale.700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 6,
          height: 6,
          position: "fixed",
          top: 4,
          right: 4,
          cursor: "pointer",

          svg: {
            width: [6, 5],
          },
        }}
        onClick={toggleCart}
      >
        <Icon name="x" />
      </Box>
      <Box ref={footerAnchorRef} />
      {isActivated && <ZendeskWidget />}
    </Flex>
  )
}
