import React from "react"
import { Link } from "gatsby"
import handleReferenceLink from "./functions/handleReferenceLink"
import { trackNavigationItemClicked } from "../services/analytics"

const renderNavigationItem = (
  name,
  item,
  locale,
  menuType,
  headerOptions,
  setHeaderOptions,
  setLockedMobile
) => {
  if (!item || (!item.linkTo && !item.reference)) {
    return <span>{name}</span>
  }

  const link =
    item.linkTo?.indexOf("https") === 0
      ? item.linkTo
      : handleReferenceLink({
          reference: item.reference,
          locale: locale,
          linkUrl: item.linkTo,
        })

  const closeMobileNavigation = () => {
    if (setHeaderOptions && setLockedMobile) {
      setHeaderOptions({
        ...headerOptions,
        offcanvasIsOpen: false,
      })
      setLockedMobile(false)
    }
  }

  return item.linkTo?.indexOf("https") === 0 ? (
    <a href={link} rel="noreferrer" target="_blank">
      {name}
    </a>
  ) : (
    <Link
      onClick={() => {
        closeMobileNavigation()
        trackNavigationItemClicked({
          name: name,
          url: link,
          menuType: menuType,
        })
      }}
      to={link}
    >
      {name}
    </Link>
  )
}

export default renderNavigationItem
