import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "theme-ui"

import DesktopMenu from "./menu-a"
import MobileProductMenu from "./menu-mobile"
import useWindowSize from "../../../hooks/useWindowSize"
import { renderMobileOrDesktop } from "../../../utils/render-util"
import { cloneDeep } from "lodash-es"

export const ProductNav = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;
  padding-left: 10px;
  position: fixed;
  top: 0;
  left: 0;

  color: ${(props) => props.theme.colors.primary};

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  z-index: 8500;
  background-color: ${(props) => props.theme.colors.grayscale[100]};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    background-color: transparent;
    padding-top: 40px;
    padding-left: 0px;
    transition: transform 400ms ease-out;
    transform: translateX(-100%);
    width: 40%;
    min-width: 40%;

    > div {
      flex: 1 1 50%;
    }

    ${(props) =>
      props.activeImage &&
      `
      width: 80%;
      `}

    ${(props) =>
      props.visible &&
      `
      transform: translateX(0%);
    `}
  }
`

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 60px;
  overflow-y: auto;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    background-color: ${(props) => props.theme.colors.grayscale[100]};
    padding-left: 112px;
    padding-bottom: 0px;
  }
`

export const ImageContainer = styled(Box)`
  display: none;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: block;
  }
`

export const Products = styled.div`
  margin-top: 90px;

  a {
    text-decoration: none;
  }
  .close {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-top: calc(${(props) => props.theme.navbar.height} - 0.5rem);
    margin-top: ${(props) => props.theme.navbar.height};
    transition: all 400ms ease-out;

    ${(props) =>
      props.hovering &&
      `
      color: ${props.theme.colors.grayscale[400]};

      a {
        color: ${props.theme.colors.grayscale[400]};
      }
      `}
  }
`

export const CategorySection = styled.div`
  width: 100%;
  font-size: 21px;

  .collections {
    width: 100%;
    max-height: 0;
    overflow: hidden;

    &.active {
      position: absolute;
      top: ${(props) => props.theme.navbar.height};
      height: 100vh;
      padding-top: 50px;
      max-height: 100%;
      overflow: visible;
      background-color: ${(props) => props.theme.colors.grayscale[100]};
      padding-left: 10px;
    }
  }

  .back-icon {
    transform: rotate(90deg);
    width: 20px;
  }

  .category-item,
  .collection-item {
    width: 100%;
    display: block;
    text-decoration: none;
    padding-bottom: 24px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    margin-bottom: 36px;
    padding-left: 0px;
    border-bottom: ${(props) => props.theme.borders.blackTransparent};

    ${(props) =>
      props.active &&
      `
        color: ${props.theme.colors.primary};
        border-bottom: ${props.theme.borders.dark};
    `}

    .collections {
      font-size: 15px;

      &.active {
        margin-top: 0px;
        padding-top: 0px;
        max-height: 300px;
        height: 100%;
        overflow: visible;
        position: static;
        background-color: none;
        padding-left: 0px;
      }
    }

    .category-item,
    .collection-item {
      width: 100%;
      cursor: pointer;
      display: block;
      text-decoration: none;
      padding: 8px 0;
      border-bottom: 1px solid transparent;
      display: flex;
      color: inherit;
      transition: all 400ms ease-out;

      :hover {
        color: ${(props) => !props.active && props.theme.colors.primary};
        border-bottom: ${(props) => !props.active && props.theme.borders.dark};
      }
    }

    .category-item {
      :hover {
        :before {
          content: "—";
          margin-left: -24px;
          padding-right: 7px;
        }
      }
    }

    .collection-item {
      :hover {
        :before {
          content: "—";
          margin-left: -20px;
          padding-right: 8px;
        }
      }
    }
  }
`

export const RegionSelection = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.grayscale[200]};
  padding: 12px 10px;
  margin-top: 40px;
`

const query = graphql`
  query {
    main: allContentfulNavigationMenu(filter: { name: { eq: "Main" } }) {
      nodes {
        id
        name
        node_locale
        items {
          ... on ContentfulCategory {
            id
            name
            slug
            internal {
              type
            }
            collections {
              id
              name
              slug
              internal {
                type
              }
              menuImage {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [WEBP]
                  breakpoints: [750, 1400]
                )
              }
            }
          }
          ...SimplePage
        }
      }
    }
  }
`

const Container = styled(Box)``

const MenuWrapper = () => {
  const data = useStaticQuery(query)
  const windowSize = useWindowSize()

  const items = useMemo(() => {
    if (!data.main) {
      return
    }

    const translationSpecific = data.main?.nodes.find((node) => {
      const localeMatch = "en-US"
      return node.node_locale === localeMatch
    })?.items

    const items = cloneDeep(translationSpecific)

    //Manually create a shopTheBedroom collection
    const shopTheBedroom = {
      name: "Bedding Inspiration",
      fullSlug: "/shop-the-bedroom",
      internal: {
        type: "ContentfulCollection",
      },
      id: `/shop-the-bedroom`,
    }
    items
      ?.find(
        (category) =>
          category?.name?.toLowerCase() === "bedding"
      )
      ?.collections.push(shopTheBedroom)

    const sleepwearInspiration = {
      name: "Sleepwear Inspiration",
      fullSlug: "/sleepwear-inspiration",
      internal: {
        type: "ContentfulCollection",
      },
      id: "/sleepwear-inspiration",
    }

    items
      ?.find((category) => category?.name?.toLowerCase() === "sleepwear")
      ?.collections.push(sleepwearInspiration)

    return items || []
  }, [])

  const MobileComponent = <MobileProductMenu items={items} />
  const DesktopComponent = <DesktopMenu items={items} />

  return (
    <Container>
      {renderMobileOrDesktop({
        mobile: MobileComponent,
        desktop: DesktopComponent,
        size: windowSize,
      })}
    </Container>
  )
}

export default MenuWrapper
